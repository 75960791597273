@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.spinner-loader {
    border-radius: 50%;
    animation: spin 0.5s linear infinite;
    margin: auto;
}
.spinner-normal {
    width: 120px;
    height: 120px;
    border: 4px solid #f3f3f3; 
    border-top: 16px solid #4200ab;
}
.spinner-small {
    width: 16px;
    height: 16px;
    border: 1px solid #f3f3f3; 
    border-top: 2px solid #4200ab;
}
@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }}